<i18n>
ru:
  socialTitleHome: Скачайте приложение
  socialDescriptionHome: Расскажите друзьям, где можно вкусно поесть и хорошо провести время!
ua:
  socialTitleHome: Завантажте програму
  socialDescriptionHome: Розкажіть друзям, де можна смачно поїсти та добре провести час!
us:
  socialTitleHome: Download the app
  socialDescriptionHome: Tell your friends where you can eat delicious food and have a good time!
</i18n>

<template>
  <div
    v-if="appConfig.VueSettingsPreRun.MainPageSocialLinkEnable"
    :class="[
      appConfig.VueSettingsPreRun.MainPageSocialLinkEnable
        ? 'v-col-md-6'
        : 'v-col-12'
    ]"
  >
    <div class="v-social__home--item">
      <div
        class="v-title"
        v-html="translate('suggestSocial.socialTitleHome')"
      />
      <div
        class="v-desc"
        v-html="translate('suggestSocial.socialDescriptionHome')"
      />
      <common-social-networks />
    </div>
  </div>
</template>

<script setup lang="ts">
const { translate } = useI18nSanitized()
const appConfig = useAppConfig()
</script>

<style lang="scss">
@use 'assets/variables';

.v-social {
  margin-bottom: 8px;
  &__home {
    &--item {
      height: 100%;
      padding: 20px;
      box-shadow: variables.$CardShadow;
      border-radius: variables.$BorderRadius;
      .v-title {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .v-desc {
        font-size: 1.14rem;
        font-weight: 500;
        margin-bottom: 12px;
      }
    }
  }
}
</style>
